<template>
  <b-row>
    <b-col cols="12">
      <b-card class="mb-4">
        <div class="card mb-4">
          <div class="d-flex align-items-start">
            <div>
              <h4 class="card-title mb-1">
                {{ $t("GERAL.PERFIL") }}
              </h4>
            </div>
          </div>
        </div>
        <div cols="4 res-inputs">
          <input-text
            ref="nome"
            v-model="form.nome"
            :label="$t('PERMISSOES.NOME')"
            required
          />
        </div>
        <div class="card mb-4 mt-4">
          <div class="d-flex align-items-start">
            <div>
              <h4 class="card-title mb-1">{{ $t("PERMISSOES.ACESSOS") }}</h4>
            </div>
          </div>
        </div>
        <b-card no-body class="mb-4 h-overflow">
          <b-table
            head-variant="light"
            hover
            :items="items"
            :fields="campos"
            :current-page="currentPage"
            :per-page="perPage"
          >
            <template v-slot:cell(acesso)="item">
              <div class="flex">
                {{ items[item.index].label }}
              </div>
            </template>
            <template v-slot:cell(visualizar)="item">
              <div class="flex">
                <InputCheckbox
                  :id="'Visualizar' + item.item.claimName"
                  @onChange="changeAcesso(item.index, 'Visualizar')"
                  :value="
                    item.item.claimValue &&
                    item.item.claimValue.includes('Visualizar')
                      ? true
                      : false
                  "
                />
              </div>
            </template>
            <template v-slot:cell(criar)="item">
              <div class="flex">
                <InputCheckbox
                  :id="'Criar' + item.item.claimName"
                  :disabled="
                    (item.item.claimValue &&
                      !item.item.claimValue.includes('Visualizar')) ||
                    !item.item.claimValue
                      ? true
                      : false
                  "
                  @onChange="changeAcesso(item.index, 'Criar')"
                  :value="
                    item.item.claimValue &&
                    item.item.claimValue.includes('Criar')
                      ? true
                      : false
                  "
                />
              </div>
            </template>
            <template v-slot:cell(editar)="item">
              <div class="flex">
                <InputCheckbox
                  :id="'Editar' + item.item.claimName"
                  :disabled="
                    (item.item.claimValue &&
                      !item.item.claimValue.includes('Visualizar')) ||
                    !item.item.claimValue
                      ? true
                      : false
                  "
                  @onChange="changeAcesso(item.index, 'Atualizar')"
                  :value="
                    item.item.claimValue &&
                    item.item.claimValue.includes('Atualizar')
                      ? true
                      : false
                  "
                />
              </div>
            </template>
            <template v-slot:cell(excluir)="item">
              <div class="flex">
                <InputCheckbox
                  :id="'Excluir' + item.item.claimName"
                  :disabled="
                    !item.item.claimValue ||
                    (item.item.claimValue &&
                      !item.item.claimValue.includes('Visualizar'))
                      ? true
                      : false
                  "
                  @onChange="changeAcesso(item.index, 'Excluir')"
                  :value="
                    item.item.claimValue &&
                    item.item.claimValue.includes('Excluir')
                      ? true
                      : false
                  "
                />
              </div>
            </template>
          </b-table>
        </b-card>

        <b-row>
          <b-col cols="12" class="text-right">
            <b-button variant="primary" @click="salvar">{{
              $t("GERAL.SALVAR")
            }}</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import PermissoesService from "@/common/services/permissoes/permissoes.service";
import { START_LOADING, STOP_LOADING } from "@/store/Store";
import { Permissoes } from "@/common/models/Permissoes";

import InputText from "@/components/inputs/InputText";
import InputCheckbox from "@/components/inputs/InputCheckbox";
import { DEFINIR_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  name: "Formulario",
  components: {
    InputText,
    InputCheckbox,
  },
  data() {
    return {
      id: this.$route.params.id,
      form: new Permissoes({}),
      campos: [
        {
          key: "acesso",
          label: this.$t("PERMISSOES.ACESSO"),
        },
        {
          key: "visualizar",
          label: this.$t("PERMISSOES.VISUALIZAR"),
          tdClass: "visualizar",
        },
        {
          key: "criar",
          label: this.$t("PERMISSOES.CRIAR"),
          tdClass: "criar",
        },
        {
          key: "editar",
          label: this.$t("PERMISSOES.EDITAR"),
          tdClass: "editar",
        },
        {
          key: "excluir",
          label: this.$t("PERMISSOES.EXCLUIR"),
          tdClass: "excluir",
        },
      ],
      items: [
        // { claimName: "Autenticacao", label: "Autenticação" },
        { claimName: "Usuario", label: "Usuário" },
        { claimName: "Perfil", label: "Perfil" },
        { claimName: "Auditoria", label: "Auditoria", showCUD: false },
        { claimName: "Email", label: "Email" },
        { claimName: "Operacao", label: "Operação", showCUD: false },
        { claimName: "Cheques", label: "Cheques" },
        { claimName: "Cnab", label: "CNAB" },
        { claimName: "Titulos", label: "Titulos" },
        { claimName: "Configuracoes", label: "Configuração" },
        { claimName: "Favorecido", label: "Favorecido" },
        {
          claimName: "ProgramacaoPagamento",
          label: "Programação de pagamento",
        },
        { claimName: "ContaBancaria", label: "Conta Bancaria" },
        { claimName: "Relatorio", label: "Relatórios" },
        { claimName: "RelatorioCedente", label: "Relatório Cedente" },
        { claimName: "Instrucoes", label: "Instruções" },
        {
          claimName: "FiltroTodosCedentesProgramacao",
          label: "Filtro todos cedentes programação",
        },
        { claimName: "ArquivoRetorno", label: "Arquivo retorno" },
        { claimName: "Comunicado", label: "Comunicados" },
        { claimName: "ValidadorCnab", label: "Validador CNAB" },
        { claimName: "RotuloBoletos", label: "Rótulos" },
        { claimName: "NotasPromissorias", label: "Pedido" },
        { claimName: "Comercial", label: "Comercial" },
        // { claimName: "OperacaoQProf", label: "Operações QProf" },
      ],
      totalRows: 5,
      currentPage: 1,
      perPage: 0,
      pageOptions: [
        5,
        10,
        15,
        { value: 100, text: this.$t("GERAL.PAGINACAO") },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(DEFINIR_BREADCRUMB, [
      { titulo: this.$t("MENU.PERFIL") },
      {
        titulo: !this.id ? this.$t("GERAL.CADASTRAR") : this.$t("GERAL.EDITAR"),
      },
    ]);
  },
  beforeMount() {
    this.listar();
  },
  methods: {
    validarFormulario() {
      let arValidation = [];
      arValidation.push(this.$refs.nome.valid());
      return arValidation.filter((elem) => elem == false).length == 0;
    },
    listar() {
      if (this.id) {
        this.buscarPorId();
        return;
      }
      this.$store.dispatch(START_LOADING);
      PermissoesService.listar()
        .then((result) => {
          this.totalRows =
            result.data.data.paginacao.totalDeElementos / this.perPage;
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    changeCheckbox(index) {
      this.$set(
        this.items[index],
        "ativo",
        (this.items[index].ativo && this.items[index].ativo == false) ||
          !this.items[index].ativo
          ? true
          : false
      );
    },
    changeAcesso(index, acesso) {
      let itemsChange = this.items;
      if (!itemsChange[index].claimValue) itemsChange[index].claimValue = [];

      for (let i = 0; i < itemsChange[index].claimValue.length; i++) {
        if (itemsChange[index].claimValue[i] == acesso) {
          itemsChange[index].claimValue.splice(i, 1);

          this.$set(this.items, index, itemsChange[index]);
          return;
        }
      }
      itemsChange[index].claimValue.push(acesso);

      this.$set(this.items, index, itemsChange[index]);
    },
    salvar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      let formSave = {
        nome: this.form.nome,
        permissoes: [],
        id: this.id,
      };
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].claimValue) {
          formSave.permissoes.push(this.items[i]);
        }
      }

      PermissoesService.salvar(formSave)
        .then(() => {
          this.$router.push({ name: "permissoes" });
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    buscarPorId() {
      this.$store.dispatch(START_LOADING);
      PermissoesService.buscarPorId(this.id)
        .then((restul) => {
          let data = restul.data.data;
          this.form.nome = data.nome;

          for (let b = 0; b < data.permissoes.length; b++) {
            for (let i = 0; i < this.items.length; i++) {
              if (data.permissoes[b].claimType == this.items[i].claimName) {
                let claims = {
                  claimValue: data.permissoes[b].claimValues,
                  claimName: data.permissoes[b].claimType,
                  label: this.$t(
                    "PERMISSOES.ACESSO_" +
                      data.permissoes[b].claimType.toUpperCase()
                  ),
                };
                this.$set(this.items, i, claims);
              }
            }
          }
          // this.$set(this, 'items', permissoes)
        })
        .catch(() => {})
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
